/* Main container styling */
.search-page {
  text-align: center;
  padding: 10px;
}

/* Quote box styling */
.quote-box {
  max-width: 50%;
  margin: 20px auto; /* Add margin at the top to create space between the quote box and header */
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  color: #555;
  text-align: center;
}

/* Quote text styling */
.quote-text {
  font-style: italic;
  margin-bottom: 10px; /* Add space between the quote and the author */
}

/* Quote author styling */
.quote-author {
  font-weight: bold;
}



/* Image styling */
.open-book-image {
  width: 40%;
  height: auto;
  display: block;
  margin: 20px auto;
  border: 1px solid #555;
  border-radius: 5px;
  box-shadow: 2px 2px 2px #555;
}

.check-boxes {
  width: 22%;
  display: inline-block;
}

/* Search bar container styling */
.search-bar-container {
  text-align: center;
  margin-bottom: 20px;
}

/* Results container styling */
.results-container {
  text-align: left;
}

/* Book item styling */
.book-item {
  margin-bottom: 20px;
  border: 1px solid #e0e0e0;
  padding: 15px;
  border-radius: 8px;
  background-color: #fafafa;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Book container styling */
.book-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

/* Book cover styling */
.book-cover {
  width: 100px;
  height: auto;
  margin-right: 20px;
}

/* No cover available styling */
.no-cover-placeholder {
  font-style: italic;
  color: #888888;
}

/* Button container styling */
.book-actions {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

/* Button styling */
.book-actions button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  margin: 5px;
}

/* "Add to Wishlist" button styling */
.book-actions .add-to-wishlist {
  background-color: #d13525;
  color: white;
  border: 2px solid #d13525;
}

.book-actions .add-to-wishlist:hover {
  background-color: #a5281b;
}

/* "Add to Completed" button styling */
.book-actions .add-to-completed {
  background-color: white;
  color: #d13525;
  border: 2px solid #d13525;
}

.book-actions .add-to-completed:hover {
  background-color: #f2f2f2;
  color: #a5281b;
}

/* Loading message styling */
.loading-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

/* Spinning logo styling */
.spinning-logo {
  width: 100px;
  height: auto;
  margin-bottom: 10px;
  animation: spin 4s linear infinite;
}

/* Spinner animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.search-page h4 {
  text-align: center;
}

