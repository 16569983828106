/* SearchBar.css */

.search-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .search-input {
    width: 300px;
    padding: 10px 15px;
    border: 2px solid #ccc;
    border-radius: 25px 0 0 25px;
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s ease-in-out;
  }
  
  .search-input:focus {
    border-color: #d13525; /* Change border color on focus */
  }
  
  .search-button {
    padding: 10px 20px;
    border: 2px solid #d13525;
    background-color: #d13525; /* Button background color */
    color: white;
    font-size: 16px;
    border-radius: 0 25px 25px 0;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  .search-button:hover {
    background-color: #b72f20; /* Darken button color on hover */
  }
  
  .search-input::placeholder {
    color: #999;
  }
  
  /* For smaller screens, adjust the width */
  @media (max-width: 600px) {
    .search-input {
      width: 200px;
    }
  
    .search-button {
      padding: 10px 15px;
    }
  }